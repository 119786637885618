import React, {ReactElement, useContext, useEffect} from "react";
import classNames from "classnames";
import {AuthenticationController, UserController} from "../../backends/YiiRoutes";
import {withoutLocaleInURI} from "../../internationalization/i18nURI";
import {StoreContext} from "../../stores/StoreLoader";
import URI from 'urijs';
import ClickableLink from "../utilities/ClickableLink";
import styles from "./styles/UserDropDownMenu.module.scss";
import {observer} from "mobx-react";
import {LogoutButton} from "../admin/sidebar/items/LogoutButton";
import {isSchoolBlocksApp, isSchoolFeedStandalone} from "../../utils/SchoolBlocksUtilities";
import useClickOutside from "../../hooks/useClickOutside";
import {buildCssUrlString} from "../../utils/StringUtilities";
import {NotificationsIcon} from "./NotificationsIcon";

export interface UserMenuDropDownItem {
    onClick?: () => void,
    path?: string,
    title: string | ReactElement,
    icon: string,
}

export function useUserMenuLinks(additionalFields: UserMenuDropDownItem[] = []): UserMenuDropDownItem[] {
    const {i18nStore, userStore, organizationStore, modalStore} = useContext(StoreContext);
    const isSchoolBlocks = isSchoolBlocksApp();

    if (!userStore.id) {
        return [{
            icon: "fas fa-sign-in-alt",
            title: isSchoolFeedStandalone() ? "Join the Discussion" : "Login",
            path: AuthenticationController.login(i18nStore.currentOrgSupportedLocale),
        }]
    } else {
        return [
            ...(isSchoolBlocks ? [{
                path: UserController.myCalendar(i18nStore.currentOrgSupportedLocale),
                title: "My Calendar",
                icon: "far fa-calendar",
            }] : []),
            ...(organizationStore.isMyCleverFeed ? [] : [{
                path: new URI(withoutLocaleInURI("/feed")).setSearch({following: true}).toString(),
                title: "My Feed",
                icon: "fas fa-rss",
            }]),
            ...[{
                onClick: () => {
                    if (document.activeElement) {
                        // prevents weird behavior on modal close when modal returns focus to activeElement
                        (document.activeElement as HTMLElement).blur();
                    }
                    modalStore.addModal({type: "activity"});
                },
                title: <>My Activity <NotificationsIcon /></>,
                icon: "fa-regular fa-bell",
            }],
            ...(isSchoolBlocks ? [{
                path: UserController.settings(i18nStore.currentOrgSupportedLocale),
                title: "My Information",
                icon: "fas fa-cog",
            }] : []),
            ...(isSchoolBlocks && userStore.isAdmin ? [{
                path: "/cms/admin/organizationSettings",
                title: "Configuration",
                icon: "fas fa-cog",
            }] : []),
            ...(isSchoolBlocks && userStore.isEditor ? [{
                path: new URI(withoutLocaleInURI("/dashboard")).toString(),
                title: "My Dashboard",
                icon: "fas fa-pencil",
            }] : []),
            ...additionalFields,
        ]
    }

}

const UserMenuDropDown = observer((props: {
    preventTabbing?: boolean,
    customLoginText?: string,
}) => {
    const {interfaceStore, userStore, modalStore} = useContext(StoreContext);

    const links = useUserMenuLinks();

    function onClick() {
        if (interfaceStore.userMenuOpen) {
            interfaceStore.toggleUserMenu()
        }
    }

    const dropdownRef = useClickOutside(onClick);

    const dropDownContainerClassName = classNames({
        [styles.container]: true,
        [styles.containerOpen]: interfaceStore.userMenuOpen,
    })

    const menuButtonClassName = classNames({
        "btn": true,
        "sb-organization-color-element-bg": true,
        [styles.userMenuButton]: true,
    })


    const focusCloseButton = () => {
        const closeButton = document.getElementById(`userMenuButton`) as HTMLButtonElement;
        if (closeButton) closeButton.focus();
    }

    const focusFirstButton = () => {
        const firstButton = document.getElementById('userMenu-firstbutton') as HTMLButtonElement;
        if (firstButton) firstButton.focus();
    }

    const handleKeydown = (evt) => {
        if (evt.code === "Escape") {
            focusCloseButton()
            interfaceStore.toggleUserMenu()
        }
    }
    useEffect(() => {
        if (interfaceStore.userMenuOpen) {
            window.addEventListener('keydown', handleKeydown);
            return () => {
                window.removeEventListener('keydown', handleKeydown);
            }
        }
    }, [interfaceStore.userMenuOpen]);

    return (
        <div ref={dropdownRef} className={styles.userMenu}>
            {userStore.id ? <>
                <button id="userMenuButton" onClick={() => interfaceStore.toggleUserMenu()} type="button"
                        className={menuButtonClassName}
                        style={{whiteSpace: "nowrap"}} aria-label="Open menu drawer"
                        tabIndex={props.preventTabbing ? -1 : 0}>
                    {userStore.avatar && <div className="sb-navbar-button-image"
                                              style={{backgroundImage: buildCssUrlString(userStore.avatar)}}/>}
                    <div className="sb-navbar-button-text" aria-hidden="true">
                        My Account
                    </div>
                    {userStore.id && <NotificationsIcon className={styles.notificationIcon} />}
                </button>
                <div className={dropDownContainerClassName}>
                    <ul>
                        {links.map((link, i) => {
                            const isCurrentPath = URI(interfaceStore.currentFullUrl).path() === link.path;

                            return <li key={i}>
                                {link.onClick ? <button
                                    onClick={() => {
                                        onClick();
                                        if (link.onClick) {
                                            link.onClick();
                                        }
                                    }}
                                    className={`${isCurrentPath ? "sb-organization-color-element-outline" : ""}`}
                                    id={i === 0 ? 'userMenu-firstbutton' : undefined}
                                    tabIndex={interfaceStore.userMenuOpen ? 0 : -1}
                                >
                                    <div>
                                        <span className={classNames(link.icon, styles.icon)}/>
                                    </div>
                                    <span>{link.title}</span>
                                </button> : <ClickableLink
                                    href={link.path}
                                    id={i === 0 ? 'userMenu-firstbutton' : undefined}
                                    tabIndex={interfaceStore.userMenuOpen ? 0 : -1}
                                    type="button"
                                    onClick={onClick}
                                    className={`${isCurrentPath ? "sb-organization-color-element-outline" : ""}`}>
                                    <div>
                                        <span className={classNames(link.icon, styles.icon)}/>
                                    </div>
                                    <span>{link.title}</span>
                                </ClickableLink>}
                            </li>
                        })}
                        <li>
                            <LogoutButton tabIndex={interfaceStore.userMenuOpen ? 0 : -1}>
                                <div>
                                    <span className={classNames('fas fa-times', styles.icon)}/>
                                </div>
                                <span>Logout</span>
                            </LogoutButton>
                        </li>
                        <span tabIndex={interfaceStore.userMenuOpen ? 0 : -1}
                              onFocus={focusFirstButton}
                        />
                    </ul>
                </div>
            </> : <div>
                <button className={menuButtonClassName}
                        style={{minWidth: 'unset'}}
                        aria-label={"Open Login Modal"}
                        id="loginMenuButton"
                        onClick={() => {
                            modalStore.addModal({
                                type: "login",
                                size: "lg",
                                id: "login-modal",
                            })
                }}>
                    <span className={"sb-navbar-button-text"}>{props.customLoginText || "Login"}</span>
                </button>
            </div>}
        </div>
    )
});
export default UserMenuDropDown;

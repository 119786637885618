import {useContext, PropsWithChildren, useState, useRef, useEffect} from "react";
import {observer} from "mobx-react";
import ClickableLink from "../../../utilities/ClickableLink";
import {ErrorBoundary} from "../../../utilities/ErrorBoundary";
import FontAwesome from "../../../utilities/FontAwesome";
import MobileNav, {navigationContent} from './MobileNav';
import {StoreContext} from "../../../../stores/StoreLoader";
import styles from '../../styles/MobileNavFooter.module.scss';
import {useMainUrl} from "../../../../hooks/useMainUrl";
import {withoutLocaleInURI} from "../../../../internationalization/i18nURI";
import classNames from "classnames";
import {isSchoolBlocksApp} from "../../../../utils/SchoolBlocksUtilities";
import {useUserMenuLinks} from "../../UserDropDownMenu";
import {NotificationsIcon} from "../../NotificationsIcon";

let scrollTimeout;

export const MobileNavFooter = observer((props: PropsWithChildren<{}>) => {
    const {interfaceStore, organizationStore, userStore, modalStore} = useContext(StoreContext);
    const accountIdentifier = 'account-id';
    const navigationIdentifier = 'nav-id';
    const schoolFeedIdentifier = 'schoolfeed-id';
    const mainUrl = useMainUrl();

    const [isVisible, setIsVisible] = useState(true);
    const prevScrollPosition = useRef(interfaceStore.scrollPosition);
    const userMenuLinks = useUserMenuLinks([{
        title: 'Logout',
        path: "/logout",
        icon: 'fas fa-sign-out-alt',
    }]);

    useEffect(() => {
        const mobileMenuButtons = document.getElementsByClassName(styles.listItem)
        const firstChildrenArray = Array.from(mobileMenuButtons).map(item => item.querySelector(':first-child'));
        const menuButtonFocused = firstChildrenArray.find(menuitem => menuitem === document.activeElement)
        clearTimeout(scrollTimeout);
        if (interfaceStore.scrollPosition > prevScrollPosition.current && !menuButtonFocused) {
            setIsVisible(false);
        } else if (!isVisible) {
            scrollTimeout = setTimeout(() => setIsVisible(true), 150);
        }
        prevScrollPosition.current = interfaceStore.scrollPosition;
    }, [interfaceStore.scrollPosition]);

    const menuItems = [<li className={styles.listItem} key={'Home'}>
        <ClickableLink href={mainUrl}
                       title={'Home'}
                       className={styles.menuButton}
        >
            <span>
                <FontAwesome prefix={'fas'} name={'fa-home fa-2x'}/>
                <span className={styles.menuItemName}>{'Home'}</span>
            </span>
        </ClickableLink>
    </li>];

    menuItems.push(<li className={styles.listItem} key={accountIdentifier}>
        <button aria-label={'Open Account Menu'}
                onClick={() => handleClick(accountIdentifier)}
                className={styles.menuButton}
        >
            <span>
                <FontAwesome name={'fa-user fa-2x'} prefix={'fas'}/>
                <span className={styles.menuItemName}>{'Account'}</span>
            </span>
        </button>
    </li>);

    isSchoolBlocksApp() && menuItems.push(<li className={styles.listItem} key={schoolFeedIdentifier}>
        <ClickableLink
            href={withoutLocaleInURI('/feed?following=true')}
            className={styles.menuButton}
            id={'SchoolFeedMobileNav'}
            title={'My Feed'}>
                <span>
                    <FontAwesome prefix={'fab'} name={'fa-stripe-s fa-2x'}/>
                    <span className={styles.menuItemName}>{'My Feed'}</span>
                </span>
        </ClickableLink>
    </li>);

    if (userStore.id) {
        menuItems.push(<li className={styles.listItem} key={"my-activity"}>
            <button
                aria-label={'Open My Activity List'}
                onClick={() => modalStore.addModal({type: "activity"})}
                className={styles.menuButton}
            >
                <span className={styles.menuButton}>
                    <span className={`fa-2x fa-fw fa-regular fa-bell ${styles.notifications}`}>
                        <NotificationsIcon />
                    </span>
                    <span className={styles.menuItemName}>{'My Activity'}</span>
                </span>
            </button>

        </li>)
    }

    menuItems.push(<li className={styles.listItem} key={navigationIdentifier}>
        <button
            aria-label={'Open Navigation Menu'}
            onClick={() => handleClick(navigationIdentifier)}
            className={styles.menuButton}
            id={'MobileNavigationMenu'}
        >
            <span>
                <FontAwesome name={'fa-bars fa-2x'} prefix={'fas'}/>
                <span className={styles.menuItemName}>{'Menu'}</span>
            </span>
        </button>
    </li>);

    function handleClick(eventId) {
        // on click, toggle clicked one and close the others.
        switch (eventId) {
            case accountIdentifier:
                if (interfaceStore.isMobileNavMenuOpen) interfaceStore.toggleMobileNavMenu();
                if (interfaceStore.schoolFeedWidgetOpen) interfaceStore.toggleSchoolFeedWidgetOpen();
                interfaceStore.toggleMobileAccountMenu();
                break;

            case navigationIdentifier:
                if (interfaceStore.isMobileAccountMenuOpen) interfaceStore.toggleMobileAccountMenu();
                if (interfaceStore.schoolFeedWidgetOpen) interfaceStore.toggleSchoolFeedWidgetOpen();
                interfaceStore.toggleMobileNavMenu();
                break;

            case schoolFeedIdentifier:
                if (interfaceStore.isMobileAccountMenuOpen) interfaceStore.toggleMobileAccountMenu();
                if (interfaceStore.isMobileNavMenuOpen) interfaceStore.toggleMobileNavMenu();
                interfaceStore.toggleSchoolFeedWidgetOpen();
                break;
        }
    }

    const containerClassName = classNames({
        [styles.container]: true,
        [styles.containerHidden]: !isVisible,
    })

    return <ErrorBoundary>
        <div className={containerClassName}>
            <ul className={styles.unorderedList}>
                {menuItems}
            </ul>
        </div>
        <MobileNav isOpen={interfaceStore.isMobileAccountMenuOpen}
                   toggleOpen={interfaceStore.toggleMobileAccountMenu}
                   identifier={accountIdentifier}
                   content={userMenuLinks.map(l => ({
                       icon: l.icon,
                       label: l.title,
                       externalUrl: false,
                       to: l.path,
                   }))}
        />
        <MobileNav isOpen={interfaceStore.isMobileNavMenuOpen}
                   toggleOpen={interfaceStore.toggleMobileNavMenu}
                   identifier={navigationIdentifier}
                   content={navigationContent(organizationStore)}
        />
    </ErrorBoundary>
})
